import React from 'react';
import { Formik, Form, FieldArray, FormikProps, Field, FieldProps } from 'formik';
import classNames from 'classnames';
import _ from 'lodash';

import * as yup from 'yup';

import { dataLayerEvents } from 'client/utils/gtag';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import { PersonDataT } from 'models/lead';
import { leadApi } from 'client/redux/api/lead';
import { ENDPOINT_ERROR, lead } from 'endpoints/index';
import { CONFIG, FILE_SIZE, SUPPORTED_FILE_FORMATS } from 'constants/index';
import { selectGeneralSettings } from 'client/redux/settings/selectors';
import { setPopUp } from 'client/redux/ui';

import TextField from '../TextField';
import TextFieldInput from '../TextFieldInput';
import TextArea from '../TextArea';
import MediaField from '../MediaField';
import Button from '../Button';

import plusIcon from 'client/assets/icons/form/plus.png';
import minusIcon from 'client/assets/icons/form/minus.png';

import css from './Form.module.scss';

interface Props {
  className?: string;
}

type FormData = Omit<lead.CreateParams, 'parents' | 'children' | 'grandParents' | 'confirmation'> & {
  parents: Array<PersonDataT>;
  children: Array<PersonDataT>;
  grandParents: Array<PersonDataT>;
  confirmation: boolean;
};

const validation = (translate: any) => {
  const validationSchema = yup.object().shape({
    parents: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .max(20, translate('errors.wrongStructure.name'))
          .required(translate('errors.required.parents.name')),
        age: yup.string().required(translate('errors.required.parents.age')),
      }),
    ),
    children: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .max(20, translate('errors.wrongStructure.name'))
          .required(translate('errors.required.parents.name')),
        age: yup.string().required(translate('errors.required.parents.age')),
      }),
    ),
    grandParents: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .max(20, translate('errors.wrongStructure.name'))
          .required(translate('errors.required.parents.name')),
        age: yup.string().required(translate('errors.required.parents.age')),
      }),
    ),
    phone: yup
      .string()
      .required(translate('errors.required.phone'))
      .test('len', translate('errors.wrongStructure.phone'), (val) => typeof val === 'string' && val.length === 10),
    address: yup.string().required(translate('errors.required.address')),
    email: yup.string().email(translate('errors.wrongStructure.email')).required(translate('errors.required.email')),
    tripFeedback: yup
      .string()
      .max(1000, translate('errors.wrongStructure.tripFeedback'))
      .required(translate('errors.required.tripFeedback')),
    socialLink: yup.string(),

    media: yup
      .mixed()
      .test('fileFormat', translate('errors.wrongStructure.media.wrongType'), (value) =>
        value ? SUPPORTED_FILE_FORMATS.includes(value.type) : true,
      )
      .test('fileSize', translate('errors.wrongStructure.media.heavy'), (value) =>
        value ? value.size <= FILE_SIZE : true,
      )
      .nullable(),
    confirmation: yup
      .boolean()
      .oneOf([true], translate('errors.required.confirmation'))
      .required(translate('errors.required.confirmation')),
  });

  return validationSchema;
};

const formValues: FormData = {
  parents: [{ name: '', age: '' }],
  children: [],
  grandParents: [],
  phone: '',
  email: '',
  address: '',
  tripFeedback: '',
  socialLink: '',
  media: '' as string & File,
  confirmation: false,
};

const FormMain: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectGeneralSettings);
  const formikRef = React.useRef<FormikProps<FormData>>(null);
  const { translate } = useTranslation('form');
  const [createLead, createLeadResult] = leadApi.useCreateLeadMutation();
  const dataLayerInstance = new dataLayerEvents();
  React.useEffect(() => {
    if (settings.isClosed) {
      dispatch(setPopUp('registration-closed'));
    }
  }, []);
  React.useEffect(() => {
    const formik = formikRef.current;

    if (!createLeadResult.data?.success && createLeadResult.data?.code === ENDPOINT_ERROR.ITEM_ALREADY_EXISTS) {
      // set pop up with type 'item-already-exist'
      dispatch(setPopUp('item-already-exist'));
    } else if (createLeadResult.data?.success) {
      // set pop up with type 'success'
      dispatch(setPopUp('success'));
      dataLayerInstance.onFormSent(CONFIG.domain);
      formik?.resetForm({ values: _.cloneDeep(formValues) });
      window.scrollTo(0, 0);
    }
  }, [createLeadResult.data, dispatch]);

  const handleSubmit = async (values: FormData) => {
    if (settings.isClosed) {
      dispatch(setPopUp('registration-closed'));
    } else {
      await createLead({
        ...values,
        parents: JSON.stringify(values.parents),
        children: JSON.stringify(values.children),
        grandParents: JSON.stringify(values.grandParents),
        confirmation: JSON.stringify(values.confirmation),
      });
    }
  };

  const handleMultiChange = (
    setFieldValue: any,
    setFieldTouched: any,
    name: string,
    field: string,
    arr: Array<any>,
    index: number,
    value: string,
    touched: any,
  ) => {
    const arrCopy = [...arr];
    arrCopy[index][field] = value;
    setFieldValue(name, arrCopy);
  };

  const getMultilineErrors = (errors: any, touched: any, name: string, index: number, field: string) => {
    if (!_.get(touched, `${name}.${index}.${field}`)) {
      return false;
    }
    return errors[name] && errors[name][index] && errors[name][index][field];
  };

  const addNewMultiField = (setFieldValue: any, name: string, arr: Array<any>) => {
    const arrCopy = [...arr];
    arrCopy.push({ name: '', age: '' });

    setFieldValue(name, arrCopy);
  };

  return (
    <div className={css.formWrapper}>
      <div className={css.title} dangerouslySetInnerHTML={{ __html: translate('title') }} />
      <div className={css.form}>
        <Formik
          innerRef={formikRef}
          initialValues={_.cloneDeep(formValues)}
          onSubmit={handleSubmit}
          validationSchema={validation(translate)}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, isSubmitting, errors, touched, resetForm, ...rest }) => {
            return (
              <Form>
                <div className={css.label}>{translate('labels.parents')}</div>
                <div className={css.inputWrapper}>
                  <div className={css.inputCol}>
                    <FieldArray
                      name="parents"
                      render={(arrayHelpers) => (
                        <div className={css.inputWrapper}>
                          {values.parents.map((parent, index) => (
                            <div className={css.fieldContainer} key={`parents-${index}`}>
                              <div className={css.inputCol}>
                                <TextField
                                  name={`parents.${index}.name`}
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    handleMultiChange(
                                      arrayHelpers.form.setFieldValue,
                                      arrayHelpers.form.setFieldTouched,
                                      'parents',
                                      'name',
                                      values.parents,
                                      index,
                                      evt.target.value,
                                      touched,
                                    )
                                  }
                                  placeholder={`${translate('textFields.placeholders.parentsName')}  ${index + 1}`}
                                  type="text"
                                />
                                <span className={css.errors}>
                                  {getMultilineErrors(errors, touched, 'parents', index, 'name')}
                                </span>
                                <TextField
                                  name={`parents.${index}.age`}
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    handleMultiChange(
                                      arrayHelpers.form.setFieldValue,
                                      arrayHelpers.form.setFieldTouched,
                                      'parents',
                                      'age',
                                      values.parents,
                                      index,
                                      evt.target.value,
                                      touched,
                                    )
                                  }
                                  placeholder={`${translate('textFields.placeholders.parentsAge')}  ${index + 1}`}
                                  type="number"
                                />
                                <span className={css.errors}>
                                  {getMultilineErrors(errors, touched, 'parents', index, 'age')}
                                </span>
                              </div>
                              {index === values.parents.length - 1 && (
                                <div className={classNames(css.row, values.parents.length === 1 && css.single)}>
                                  {values.parents.length < 5 && (
                                    <img
                                      src={plusIcon}
                                      alt=""
                                      onClick={() =>
                                        addNewMultiField(arrayHelpers.form.setFieldValue, 'parents', values.parents)
                                      }
                                    />
                                  )}

                                  {values.parents.length > 1 && (
                                    <img src={minusIcon} alt="" onClick={() => arrayHelpers.remove(index)} />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={css.label}>{translate('labels.children')}</div>

                <div className={css.inputWrapper}>
                  <div className={css.inputCol}>
                    <FieldArray
                      name="children"
                      render={(arrayHelpers) => (
                        <div className={css.inputWrapper}>
                          {values.children.length === 0 && (
                            <Button
                              label={translate('addChildBtn')}
                              type="button"
                              disabled={isSubmitting}
                              className={css.addFieldBtn}
                              onClick={() =>
                                addNewMultiField(arrayHelpers.form.setFieldValue, 'children', values.children)
                              }
                            />
                          )}
                          {values.children.map((child, index) => (
                            <div className={css.fieldContainer} key={`children-${index}`}>
                              <div className={css.inputCol}>
                                <TextField
                                  name={`children.${index}.name`}
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    handleMultiChange(
                                      arrayHelpers.form.setFieldValue,
                                      arrayHelpers.form.setFieldTouched,
                                      'children',
                                      'name',
                                      values.children,
                                      index,
                                      evt.target.value,
                                      touched,
                                    )
                                  }
                                  placeholder={`${translate('textFields.placeholders.childrensName')}  ${index + 1}`}
                                  type="text"
                                />
                                <span className={css.errors}>
                                  {getMultilineErrors(errors, touched, 'children', index, 'name')}
                                </span>
                                <TextField
                                  name={`children.${index}.age`}
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    handleMultiChange(
                                      arrayHelpers.form.setFieldValue,
                                      arrayHelpers.form.setFieldTouched,
                                      'children',
                                      'age',
                                      values.children,
                                      index,
                                      evt.target.value,
                                      touched,
                                    )
                                  }
                                  placeholder={`${translate('textFields.placeholders.childrensAge')}  ${index + 1}`}
                                  type="number"
                                />
                                <span className={css.errors}>
                                  {getMultilineErrors(errors, touched, 'children', index, 'age')}
                                </span>
                              </div>
                              {index === values.children.length - 1 && (
                                <div className={css.row}>
                                  {values.children.length < 10 && (
                                    <img
                                      src={plusIcon}
                                      alt=""
                                      onClick={() =>
                                        addNewMultiField(arrayHelpers.form.setFieldValue, 'children', values.children)
                                      }
                                    />
                                  )}
                                  {values.children.length > 0 && (
                                    <img src={minusIcon} alt="" onClick={() => arrayHelpers.remove(index)} />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={css.label}>{translate('labels.grandparents')}</div>
                <div className={css.inputWrapper}>
                  <div className={css.inputCol}>
                    <FieldArray
                      name="grandParents"
                      render={(arrayHelpers) => (
                        <div className={css.inputWrapper}>
                          {values.grandParents.length === 0 && (
                            <Button
                              label={translate('addGrandparentBtn')}
                              type="button"
                              disabled={isSubmitting}
                              className={css.addFieldBtn}
                              onClick={() =>
                                addNewMultiField(arrayHelpers.form.setFieldValue, 'grandParents', values.grandParents)
                              }
                            />
                          )}
                          {values.grandParents.map((grandParent, index) => (
                            <div className={css.fieldContainer} key={`grand-parent-${index}`}>
                              <div className={css.inputCol}>
                                <TextField
                                  name={`grandParents.${index}.name`}
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    handleMultiChange(
                                      arrayHelpers.form.setFieldValue,
                                      arrayHelpers.form.setFieldTouched,
                                      'grandParents',
                                      'name',
                                      values.grandParents,
                                      index,
                                      evt.target.value,
                                      touched,
                                    )
                                  }
                                  placeholder={`${translate('textFields.placeholders.grandparentsName')}  ${index + 1}`}
                                  type="text"
                                />
                                <span className={css.errors}>
                                  {getMultilineErrors(errors, touched, 'grandParents', index, 'name')}
                                </span>
                                <TextField
                                  name={`grandParents.${index}.age`}
                                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                    handleMultiChange(
                                      arrayHelpers.form.setFieldValue,
                                      arrayHelpers.form.setFieldTouched,
                                      'grandParents',
                                      'age',
                                      values.grandParents,
                                      index,
                                      evt.target.value,
                                      touched,
                                    )
                                  }
                                  placeholder={`${translate('textFields.placeholders.grandparentsAge')}  ${index + 1}`}
                                  type="number"
                                />
                                <span className={css.errors}>
                                  {getMultilineErrors(errors, touched, 'grandParents', index, 'age')}
                                </span>
                              </div>
                              {index === values.grandParents.length - 1 && (
                                <div className={css.row}>
                                  {values.grandParents.length < 10 && (
                                    <img
                                      src={plusIcon}
                                      alt=""
                                      onClick={() =>
                                        addNewMultiField(
                                          arrayHelpers.form.setFieldValue,
                                          'grandParents',
                                          values.grandParents,
                                        )
                                      }
                                    />
                                  )}
                                  {values.grandParents.length > 0 && (
                                    <img src={minusIcon} alt="" onClick={() => arrayHelpers.remove(index)} />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={css.inputWrapper}>
                  <TextFieldInput
                    name="address"
                    placeholder={translate('textFields.placeholders.address')}
                    classname={css.inputLarge}
                  />
                  <TextFieldInput
                    name="phone"
                    type="tel"
                    placeholder={translate('textFields.placeholders.phone')}
                    classname={css.inputLarge}
                  />
                  <TextFieldInput
                    name="email"
                    placeholder={translate('textFields.placeholders.email')}
                    classname={css.inputLarge}
                  />
                </div>
                <div className={css.label}>{translate('labels.familyTrip')}</div>
                <TextArea
                  name="tripFeedback"
                  placeholder={translate('textFields.placeholders.textarea')}
                  wordsLimitText={translate('textFields.textAreaWordsLimit')}
                />
                <div className={css.label}>{translate('labels.photo')}</div>
                <MediaField
                  name="media"
                  media={values.media}
                  placeholder={translate('textFields.placeholders.mediaField')}
                  mediaFieldDefinition={translate('mediaFieldDefinition')}
                  mediaFieldFormats={translate('mediaFieldFormats')}
                />
                <div className={css.label}>{translate('labels.socialsLink')}</div>
                <div className={css.inputWrapper}>
                  <TextFieldInput
                    name="socialLink"
                    placeholder={translate('textFields.placeholders.link')}
                    classname={css.inputLargeSingle}
                  />
                </div>
                <div className={css.bottomDescriptionWrapper}>
                  <span className={css.star}>*</span>
                  <div className={css.descriptionContentWrapper}>
                    <p>{translate('bottomDescription.line1')}</p>
                    <p>{translate('bottomDescription.line2')}</p>
                    <p>{translate('bottomDescription.line3')}</p>
                    <p>{translate('bottomDescription.line4')}</p>
                    <p>{translate('bottomDescription.line5')}</p>
                    <p>{translate('bottomDescription.line6')}</p>
                  </div>
                </div>
                <div className={css.buttonWrapper}>
                  <Field
                    name="confirmation"
                    render={({ field, meta }: FieldProps<FormData>) => (
                      <div className={css.checkboxWrapper}>
                        <input
                          {..._.omit(field, ['value'])}
                          checked={field.value as unknown as boolean}
                          type="checkbox"
                        />
                        <div
                          className={css.confirmation}
                          dangerouslySetInnerHTML={{ __html: translate('confirmation') }}
                        />
                        {meta.touched && !!meta.error && (
                          <span className={`${css.errors} ${css.confirm}`}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  />

                  <Button
                    label={translate('submitBtn')}
                    type="submit"
                    disabled={isSubmitting}
                    className={css.submitButton}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default FormMain;
