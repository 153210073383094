import * as React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import css from './TextField.module.scss';

interface Props {
  classname?: string;
  name?: string;
  placeholder?: string;
  onChange?: (evt: any) => void;
  type?: string;
}

const TextFieldInput: React.FC<Props> = (props) => {
  const { classname = '', name = '', placeholder = '', onChange, type = '', ...rest } = props;

  const [field, meta, helpers] = useField(name);

  return (
    <div className={css.container}>
      <input className={classNames(css.formInput, classname)} placeholder={placeholder} {...field} type={type} />
      <span>{meta.error && meta.touched ? meta.error : ''}</span>
    </div>
  );
};

export default TextFieldInput;
