import React from 'react';
import cn from 'classnames';

import Header from 'client/components/common/Header';
import FormMain from 'client/components/common/Form';

import css from './Main.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;

  return (
    <div className={cn(css.main, className)}>
      <Header />
      <FormMain />
    </div>
  );
};

export default Main;
