import React from 'react';
import { useField } from 'formik';
import cn from 'classnames';
import _ from 'lodash';

import css from './TextArea.module.scss';

const FIELD_LIMIT = 1000;

interface Props {
  classname?: string;
  name?: string;
  placeholder?: string;
  wordsLimitText?: string;
}

const TextArea: React.FC<Props> = (props) => {
  const { classname = '', name = '', placeholder = '', wordsLimitText = '' } = props;
  const [field, meta, helpers] = useField(name);
  const countLimitLabel = wordsLimitText.replace('{{count}}', `${FIELD_LIMIT - _.size(field.value)}`);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    helpers.setValue(e.target.value.slice(0, FIELD_LIMIT));
  };

  return (
    <div className={css.container}>
      <textarea
        {...field}
        onChange={handleOnChange}
        className={cn(css.textarea, classname)}
        rows={4}
        cols={50}
        placeholder={placeholder}
      />
      <span className={css.error}>{meta.error && meta.touched && meta.error}</span>
      <div className={css.wordsLimit}>{countLimitLabel}</div>
    </div>
  );
};

export default TextArea;
