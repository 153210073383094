import * as yup from 'yup';

import { Lead } from 'models/index';

import { endpoint, EndpointError } from '../endpoint';
import { ValidationShape, ERROR as VALIDATION_ERROR } from '../validation';

export type CreateParams = Omit<Lead.Model, 'mediaUrl' | 'id' | 'parents' | 'children' | 'grandParents'> & {
  id?: Lead.Model['id'];
  media?: File;
  parents: string;
  children: string;
  grandParents: string;
  confirmation: string;
};
export type CreateResult = { id: Lead.Model['id'] };
export type CreateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  id: yup.string(),
  email: yup.string().required(VALIDATION_ERROR.REQUIRED),
  phone: yup.string().required(VALIDATION_ERROR.REQUIRED),
  address: yup.string().required(VALIDATION_ERROR.REQUIRED),
  tripFeedback: yup.string().required(VALIDATION_ERROR.REQUIRED),
  parents: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        age: yup.string(),
      }),
    )
    .required(VALIDATION_ERROR.REQUIRED),
  children: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        age: yup.string(),
      }),
    )
    .required(VALIDATION_ERROR.REQUIRED),
  grandParents: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        age: yup.string(),
      }),
    )
    .required(VALIDATION_ERROR.REQUIRED),
  media: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) => {
      if (!value || typeof value === 'string') {
        return true;
      }

      if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(value?.mimetype || value?.type)) {
        return true;
      }

      return false;
    })
    .test('size', VALIDATION_ERROR.INVALID_SIZE, (value) => {
      if (!value || typeof value === 'string') {
        return true;
      }

      if (value && value.size && value.size <= 1024 * 1024 * 3) {
        return true;
      }

      return false;
    }),
  confirmation: yup.boolean().oneOf([true], VALIDATION_ERROR.INVALID_FORMAT).required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<CreateParams>);

export const update = endpoint<CreateParams, CreateResult, CreateError>({
  method: 'POST',
  url: () => `/lead`,
});
