import * as yup from 'yup';

import * as Client from 'models/client';
import { DistributiveOmit } from 'types/distributive-omit';
import { ValidationShape, ERROR as VALIDATION_ERROR } from 'endpoints/validation';
import { endpoint, EndpointError } from '../endpoint';

export type UpdateParams = DistributiveOmit<Client.Model, 'uid' | 'pictureUrl'> & {
  picture?: File;
};
export type UpdateResult = Partial<Client.Model>;
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  email: yup.string(),
  phone: yup.string(),
  name: yup.string(),
  picture: yup
    .mixed()
    .test('mimetype', VALIDATION_ERROR.INVALID_FORMAT, (value) =>
      value ? ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(value.mimetype) : true,
    ),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/client`,
});
