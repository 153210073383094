import * as yup from 'yup';

import * as Settings from 'models/settings';
import { ValidationShape, ERROR as VALIDATION_ERROR } from 'endpoints/validation';

import { endpoint, EndpointError } from '../endpoint';

export type UpdateParams = Pick<Settings.Model, 'general'>;
export type UpdateResult = Pick<Settings.Model, 'general'>;
export type UpdateError = EndpointError;

export const updateParamsSchema = yup.object().shape({
  general: yup
    .object()
    .shape({
      isClosed: yup.boolean(),
      redirectUrl: yup.string().defined().nullable(),
    })
    .required(VALIDATION_ERROR.REQUIRED),
} as ValidationShape<UpdateParams>);

export const update = endpoint<UpdateParams, UpdateResult, UpdateError>({
  method: 'POST',
  url: () => `/settings`,
});
