import * as React from 'react';

import Icon from '../Icon';
import ngIcon from 'client/assets/icons/header/ng.png';
import facebookIcon from 'client/assets/icons/header/fac.png';
import whatsappIcon from 'client/assets/icons/header/wt.png';
import tnuIcon from 'client/assets/icons/header/tnu.png';
import ynetIcon from 'client/assets/icons/header/ynet.png';

import { share } from 'utils/share';
import { detectIE } from 'utils/detect-ie';
import { useResponsive, useTranslation } from 'client/hooks';
import { CAN_USE_DOM, IS_DEV, SHARE_CONSTANTS } from 'constants/index';

import css from './Header.module.scss';
import { dataLayerEvents } from 'client/utils/gtag';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { translate } = useTranslation('cover');
  const { translate: seoTexts } = useTranslation('seo');
  const [isDesktop] = useResponsive('DESKTOP');
  const dataLayerInstance = new dataLayerEvents();

  const accessablityBtn = () => {
    if (!CAN_USE_DOM) {
      return null;
    }

    return document.querySelector('#INDmenu-btn');
  };

  const onButtonClick = () => {
    const btn = accessablityBtn();
    if (btn) {
      const eventClick = new Event('click');
      btn.dispatchEvent(eventClick);
    }
  };

  const onMouseEnter = () => {
    let eventFocus;
    const btn = accessablityBtn();
    if (!CAN_USE_DOM) {
      return;
    }

    if (isDesktop) {
      if (detectIE() > 0) {
        eventFocus = document.createEvent('Event');
        eventFocus.initEvent('focus', false, true);
      } else {
        eventFocus = new Event('focus');
      }

      if (btn) {
        btn.dispatchEvent(eventFocus);
      }
    }
  };

  const onMouseLeave = () => {
    let eventBlur;
    const btn = accessablityBtn();

    if (!CAN_USE_DOM) {
      return;
    }

    if (detectIE() > 0) {
      eventBlur = document.createEvent('Event');
      eventBlur.initEvent('blur', false, true);
    } else {
      eventBlur = new Event('blur');
    }

    if (btn) {
      btn.dispatchEvent(eventBlur);
    }
  };

  const onShare = (e: React.SyntheticEvent<HTMLImageElement>): void => {
    const type: string = `${e.currentTarget.dataset.id}`;
    const seo = {
      title: seoTexts('title'),
      description: seoTexts('description'),
    };

    if (!IS_DEV) {
      share(type, isDesktop, seo);
    }

    dataLayerInstance.onShareClick(type);
  };

  return (
    <>
      <div className={css.headerWrapper}>
        <div className={css.iconWrapper}>
          <a href="https://www.ynet.co.il/" target="_blank">
            <img src={ynetIcon} alt="" />
          </a>
          <a href="https://www.tnuva.co.il/family-time/" target="_blank">
            <img src={tnuIcon} alt="" />
          </a>
        </div>
        <div className={css.socialsWrapper}>
          <img src={whatsappIcon} alt="" onClick={onShare} data-id={SHARE_CONSTANTS.WHATSAPP} />
          <img src={facebookIcon} alt="" onClick={onShare} data-id={SHARE_CONSTANTS.FACEBOOK} />
          <img src={ngIcon} alt="" onClick={onButtonClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
        </div>
      </div>
      <div className={css.cover}>
        <Icon type="tnuca" />
        <div className={css.title}>{translate('title')}</div>
      </div>
    </>
  );
};

export default Header;
