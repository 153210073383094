export const CONFIG = JSON.parse(process.env.CONFIG || '{}');
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';

export const IS_DEV = process.env.NODE_ENV !== 'production';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const SHARE_CONSTANTS = {
  WHATSAPP: 'Whatsapp',
  FACEBOOK: 'Facebook',
};

export const LOCATIONS = [
  {
    value: '1',
    label: 'תל אביב',
  },
  {
    value: '2',
    label: 'ראשון לציון',
  },
];

export const csvFieldsLabels = {
  address: 'כתובת',
  children: {
    name: 'שם ילד',
    age: 'גיל ילד',
  },
  parents: {
    name: 'שם הורה',
    age: 'גיל הורה',
  },
  grandParents: {
    name: 'שם סבא',
    age: 'גיל סבא',
  },
  email: 'מייל',
  phone: 'טלפון',
  createdDate: 'תאריך יצירה',
  socialLink: 'לינק לסושיאל',
  tripFeedback: 'טקסט חופשי',
  media: 'תמונה',
};

export const SUPPORTED_FILE_FORMATS: string[] = ['image/png', 'image/jpeg', 'image/gif'];
export const FILE_SIZE = 5 * 1024 * 1024;
