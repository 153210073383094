import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useField } from 'formik';

import css from './TextField.module.scss';

interface Props {
  classname?: string;
  name?: string;
  placeholder?: string;
  onChange?: (evt: any) => void;
  type?: string;
}

const TextField: React.FC<Props> = (props) => {
  const { classname = '', name = '', placeholder = '', onChange, type = '' } = props;
  const [field] = useField(name);
  const formikProps = _.omit(field, ['onChange']);

  return (
    <input
      {...formikProps}
      className={classNames(css.formInput, classname)}
      placeholder={placeholder}
      onChange={onChange}
      type={type}
    />
  );
};

export default TextField;
