import _ from 'lodash';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model as ClientModel } from 'models/client';
import * as actions from './actions';

type Client = ClientModel & {
  csrfToken: string;
  auth: {
    isInProgress: boolean;
    isLoggedIn: boolean;
    error: string | null;
  };
};

const initialState = {
  uid: '',
  csrfToken: '',
  auth: {
    isInProgress: false,
    isLoggedIn: false,
    error: null,
  },
} as Client;

export const client = createSlice({
  name: 'client',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<actions.Init>) => {
      state.uid = action.payload.uid;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.pictureURL = action.payload.pictureURL;
      state.auth.isLoggedIn = true;
    },
    setCSRFToken: (state, action: PayloadAction<actions.SetCSRFToken>) => {
      state.csrfToken = action.payload.token;
    },
    update: (commonState, action: PayloadAction<actions.Update>) => {
      const state = commonState as unknown as any;

      Object.entries(action.payload).forEach(([key, value]) => {
        if (!_.isUndefined(value)) {
          state[key] = value;
        }
      });
    },
    loggedInAfterSendingData: (state, action: PayloadAction<boolean>) => {
      state.auth.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.signIn.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signIn.fulfilled, (state, action) => {
      state.auth.isInProgress = false;

      if (action.payload.success && action.payload.name) {
        state.auth.isLoggedIn = true;
      }

      if (action.payload.success) {
        state.uid = action.payload.uid;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.pictureURL = action.payload.pictureURL;
      }
    });
    builder.addCase(actions.signIn.rejected, (state) => {
      state.auth.isInProgress = false;
    });

    builder.addCase(actions.signOut.pending, (state) => {
      state.auth.isInProgress = true;
    });
    builder.addCase(actions.signOut.fulfilled, (state) => {
      state.auth.isInProgress = false;
      state.auth.isLoggedIn = false;
      state.uid = '';
      state.auth = {
        isInProgress: false,
        isLoggedIn: false,
        error: null,
      };
    });
    builder.addCase(actions.signOut.rejected, (state) => {
      state.auth.isInProgress = false;
    });
  },
});

export const { init, setCSRFToken, update, loggedInAfterSendingData } = client.actions;
export default client.reducer;
