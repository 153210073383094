import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import 'client/styles/index.scss';

import { MAIN_PAGE_ROUTE } from 'client/constants/routes';
import { useAppDispatch, useAppSelector, useTranslation } from 'client/hooks';
import { selectGeneralSettings } from 'client/redux/settings/selectors';

import PopUp from './common/PopUp';
import Main from './pages/Main';

import favicon from 'client/assets/favicon/favicon.png';
import shareImg from 'client/assets/share.jpg';

import css from './App.module.scss';
import { setPopUp } from 'client/redux/ui';

const App: React.FC = () => {
  const { translate: seoTexts } = useTranslation('seo');
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectGeneralSettings);

  React.useEffect(() => {
    if (settings.isClosed) {
      dispatch(setPopUp('registration-closed'));
    }
  }, [settings.isClosed, dispatch]);

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{seoTexts('title')}</title>
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="description" content={seoTexts('description')} />
        <meta property="og:title" content={seoTexts('ogTitle')} />
        <meta property="og:description" content={seoTexts('ogDescription')} />
        <meta property="og:image" content={shareImg} />
      </Helmet>
    );
  };

  return (
    <div className={css.app}>
      {renderHelmet()}
      <Switch>
        <Route exact path={MAIN_PAGE_ROUTE}>
          <Main />
        </Route>
        <Redirect to={MAIN_PAGE_ROUTE} />
      </Switch>

      <PopUp />
    </div>
  );
};

export default App;
