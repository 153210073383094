import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import plusIcon from 'client/assets/icons/form/plus.png';
import minusIcon from 'client/assets/icons/form/minus.png';

import css from './MediaField.module.scss';

interface Props {
  className?: string;
  name: string;
  value?: string | ArrayBuffer | null | undefined;
  onChange?: (e: React.ChangeEvent<any>) => void;
  media?: any;
  errors?: Record<string, string>;
  placeholder?: string;
  mediaFieldDefinition?: string;
  mediaFieldFormats?: string;
}

interface State {
  img: string | ArrayBuffer | null | undefined;
}

const initialState: State = {
  img: null,
};

type Action = {
  type: 'setImg';
  payload: State['img'];
};

function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'setImg':
      return { ...state, img: action.payload };

    default:
      throw new Error('Unknown action');
  }
}

const MediaField: React.FC<Props> = (props) => {
  const { className = '', name, media, placeholder, mediaFieldDefinition, mediaFieldFormats } = props;
  const fileRef = React.useRef<any>(null);
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, img: props.value });
  const [field, meta, helpers] = useField(name);
  const isErrorVisible = meta.touched && !!meta.error;

  const onChange = (e: React.ChangeEvent<any>) => {
    helpers.setTouched(true);
    const reader = new FileReader();

    reader.onloadend = () => dispatch({ type: 'setImg', payload: reader.result });
    reader.readAsDataURL(e.currentTarget.files[0]);
    helpers.setValue(e.currentTarget.files[0]);
    if (props.onChange) props.onChange(e);
  };

  const removeFile = (event: any) => {
    event.stopPropagation();
    helpers.setValue(null);
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  useEffect(() => {
    dispatch({ type: 'setImg', payload: meta.value });
  }, [meta.value]);

  return (
    <div
      className={classNames(
        css.mediaField,
        className,
        isErrorVisible && css.error,
        isErrorVisible ? css.mB15 : css.mB40,
      )}
    >
      {media && (
        <button className={css.removeFile} onClick={removeFile}>
          <img src={minusIcon} alt="" />
        </button>
      )}
      <label>
        <div className={css.wrapper}>
          {media ? (
            <div className={css.fileNameWrapper}>{media.name}</div>
          ) : (
            <>
              <img src={plusIcon} alt="" /> {placeholder}
            </>
          )}
        </div>
        <input type="file" name={name} ref={fileRef} onChange={onChange} accept=".gif,.jpeg,.jpg,.png" />
        {!isErrorVisible && (
          <div>
            <span>{mediaFieldDefinition}</span>
            &nbsp;
            <span>{mediaFieldFormats}</span>
          </div>
        )}
        {isErrorVisible && <p className={css.errorMsg}>{meta.error}</p>}
      </label>
    </div>
  );
};

export default MediaField;
