import * as React from 'react';
import classNames from 'classnames';

import Icon from './../Icon';
import css from './Button.module.scss';

interface Props {
  className?: string;
  type: 'button' | 'submit' | 'reset';
  label: string;
  labelColor?: string;
  disabled?: boolean;
  iconType?: string;
  variant?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<Props> = (props) => {
  const {
    className = '',
    label,
    type = 'button',
    labelColor = '#fff',
    disabled = false,
    iconType,
    variant = '',
    ...rest
  } = props;
  return (
    <button
      {...rest}
      style={{
        color: labelColor,
      }}
      className={classNames(
        variant === 'red' ? css.redBtn : css.button,
        disabled && css.disabled,
        iconType && css.withIcon,

        className,
      )}
      type={type}
    >
      <p className={css.label}>{label}</p>

      {iconType && <Icon type={iconType} className={classNames(css.icon)} />}
    </button>
  );
};

export default Button;
